// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;500);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MfpeqS3GLyoi82BnlBSG{display:flex;align-items:center;width:fit-content;column-gap:12px;margin:14px auto 0;background:rgba(55,55,55,.4);border-radius:16px;padding:8px 12px}.MfpeqS3GLyoi82BnlBSG .XsGeQmEmAYrUdJLGE1wr,.MfpeqS3GLyoi82BnlBSG .NPjFjPrxlG1AFFkIgvEC{display:block;position:relative;border:unset;outline:unset;background-color:rgba(0,0,0,0);margin:0;padding:8.5px 12px;border-radius:11px;border:1px solid rgba(0,0,0,0);font-size:16px;line-height:19.2px;font-weight:400;transition:.3s ease-out}.MfpeqS3GLyoi82BnlBSG .NPjFjPrxlG1AFFkIgvEC{color:#fff}.MfpeqS3GLyoi82BnlBSG .NPjFjPrxlG1AFFkIgvEC:hover{cursor:pointer;background-color:rgba(99,99,99,.4)}.MfpeqS3GLyoi82BnlBSG .NPjFjPrxlG1AFFkIgvEC:focus-visible{border-radius:0;border:1px solid #fff}.MfpeqS3GLyoi82BnlBSG .NPjFjPrxlG1AFFkIgvEC:active{color:#fff;transition:all .3s}.MfpeqS3GLyoi82BnlBSG .NPjFjPrxlG1AFFkIgvEC.bb5Lw9u2wgQJGJEJJ5z9{color:#636363;transition:all .3s;cursor:not-allowed}.MfpeqS3GLyoi82BnlBSG .XsGeQmEmAYrUdJLGE1wr{color:#fff;background-color:#636363 !important}.MfpeqS3GLyoi82BnlBSG .XsGeQmEmAYrUdJLGE1wr:focus-visible{border-radius:11px;border:1px solid #fff;background-color:#262626}.MfpeqS3GLyoi82BnlBSG .XsGeQmEmAYrUdJLGE1wr:active{background-color:#1e1e1e;transition:all .3s}.MfpeqS3GLyoi82BnlBSG .XsGeQmEmAYrUdJLGE1wr.bb5Lw9u2wgQJGJEJJ5z9{background-color:rgba(50,50,50,.6);color:#636363}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `MfpeqS3GLyoi82BnlBSG`,
	"activeTab": `XsGeQmEmAYrUdJLGE1wr`,
	"inactiveTab": `NPjFjPrxlG1AFFkIgvEC`,
	"disabled": `bb5Lw9u2wgQJGJEJJ5z9`
};
export default ___CSS_LOADER_EXPORT___;
